"use client";

import { Button, Modal, t, tw } from "@reframe.so/blocks";

import { useConfirm } from "@src/hooks/confirm";
import {
  ReadonlyURLSearchParams,
  usePathname,
  useRouter,
  useSearchParams,
} from "next/navigation";
import { useState } from "react";

const createURL = (
  href: string | { search: Record<string, string | null> },
  pathname: string | null,
  searchParams: ReadonlyURLSearchParams | null
) => {
  if (typeof href === "string") {
    return href;
  }

  const search = new URLSearchParams(searchParams ?? {});

  for (const [key, value] of Object.entries(href.search)) {
    if (value === null) {
      search.delete(key);
    } else {
      search.set(key, value);
    }
  }

  return `${pathname}?${search.toString()}`;
};

const Iframe = tw("iframe", t`absolute top-0 left-0 w-full h-full border-0`);

export const LinkButton: React.ComponentType<
  React.ComponentProps<typeof Button> & {
    href: string | { search: Record<string, string | null> };
    external?: boolean;
    confirm?: boolean;
    modal?: boolean;
  }
> = ({
  children,
  href: href,
  external,
  confirm: shouldConfirm,
  modal,
  ...props
}) => {
  const url = createURL(href, usePathname(), useSearchParams());

  const router = useRouter();
  const { confirm, Confirm } = useConfirm();
  const [modalOpen, setModalOpen] = useState(false);

  const onClick = ({ meta }: { meta: boolean }) => {
    if (external) {
      window.open(url, "_blank");
    } else if (modal) {
      if (meta) {
        window.open(url, "_blank");
      } else {
        setModalOpen(true);
      }
    } else {
      router.push(url);
    }
  };

  return (
    <Button
      data-url={url}
      {...props}
      onClick={(e) => {
        if (!shouldConfirm) {
          return onClick({
            meta: e.metaKey,
          });
        }
        e.stopPropagation();

        confirm({
          onConfirm: () => onClick({ meta: e.metaKey }),
          action: "Confirm",
        });
      }}
    >
      {children}
      <Confirm />
      {modal && modalOpen ? (
        <Modal fullScreen open={modalOpen} setOpen={setModalOpen}>
          <Iframe src={url} />
        </Modal>
      ) : null}
    </Button>
  );
};
