export const EVENT_CONST = {
  HOMEPAGE_VIEWED: "Homepage Viewed",
  SING_IN_VIEWED: "Sign In Viewed",
  SING_UP_VIEWED: "Sign Up Viewed",
  EMBEDDING_VIEWED: "Embedding Viewed",
  CHATBOT_CREATED: "Chatbot Created",
  EMBEDDING_CREATED: "Embedding Created",
  SIGN_UP_CLICKED: "Sign Up Clicked",
  SIGN_IN_CLICKED: "Sign In Clicked",
  SUCCESSFULLY_SIGNED_UP: "Successfully Signed Up",
  SUCCESSFULLY_SIGNED_IN: "Successfully Signed In",
  PRICING_VIEWED: "Pricing Viewed",
  CONTACT_VIEWED: "Contact Viewed",
  SUBCRIBE_VIEWED: "Subscription Viewed",
  SUBCRIBE_CLICKED: "Subscription Clicked",
  CREATE_WORKSPACE: "Create Workspace",
  CONVERSAION_CREATED: "Conversation Created",
  CONVERSATION: "Conversation",
  SIGN_IN_GOOGLE: "Sign In Google",
  SIGN_IN_EMAIL: "Sign In Email",
  PLAN_CLICKED: "Plan Clicked",
  SITE_VISITED: "Site Visited",
  CHATS_VIEWED: "Chats Viewed",
};
