import NextImage from "next/image";
import { ComponentProps, forwardRef } from "react";
import { Stack } from "./Stack";
import { CSS } from "./tailwind";

type NextImageProps = ComponentProps<typeof NextImage>;

type Position = "center" | "top" | "bottom" | "left" | "right";

type Props = Omit<NextImageProps, "alt" | "fill" | "width" | "height"> & {
  alt?: string;
  fill?: boolean;
  fit?: "fill" | "cover" | "contain" | "none" | "scale-down";
  position?: Position | `${Position} ${Position}`;
  css?: CSS;
};

export const Image = forwardRef<HTMLDivElement, Props>(
  (
    {
      fit = "contain",
      position = "center",
      alt = "",
      fill = true,
      css,
      ...props
    },
    ref
  ) => (
    <Stack ref={ref} css={["relative overflow-hidden h-full w-full", css]}>
      <NextImage
        alt={alt}
        fill={fill}
        style={{
          objectFit: fit,
          objectPosition: position,
        }}
        {...props}
      />
    </Stack>
  )
);

Image.displayName = "Image";
