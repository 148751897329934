"use client";

import { Modal } from "@reframe.so/blocks";
import { ConfirmModal } from "@src/components/modals/confirm";
import { useCallback, useRef, useState } from "react";

export const useConfirm = () => {
  const [open, setOpen] = useState(false);
  const onConfirmRef = useRef<(() => void) | null>();
  const [title, setTitle] = useState<string | undefined>();
  const [warning, setWarning] = useState<string | undefined>();
  const [action, setAction] = useState("Confirm");
  const [dangerous, setDangerous] = useState<number | undefined>();

  const confirm = useCallback(
    ({
      onConfirm,
      title,
      action,
      warning,
      dangerous,
    }: {
      onConfirm: () => void;
      title?: string;
      warning?: string;
      action: string;
      dangerous?: number;
    }) => {
      onConfirmRef.current = onConfirm;
      setAction(action);
      setTitle(title);
      setWarning(warning);
      setOpen(true);
      setDangerous(dangerous);
    },
    []
  );

  return {
    confirm,
    Confirm: useCallback(
      () => (
        <Modal open={open} setOpen={setOpen} title={title}>
          <ConfirmModal
            dangerous={dangerous}
            warning={warning}
            action={action}
            onClose={() => {
              onConfirmRef.current = null;
              setOpen(false);
            }}
            onConfirm={() => {
              onConfirmRef.current?.();
              onConfirmRef.current = null;
              setOpen(false);
            }}
          />
        </Modal>
      ),
      [action, open, title, warning, dangerous]
    ),
  };
};
