import { t, v, makeComponent, CSS } from "@reframe.so/blocks";

const logo = v({
  base: t`bg-transparent fill-[#111827] h-6 w-6`,
  variants: {
    white: {
      true: t`fill-white`,
      false: t``,
    },
    background: {
      true: t`bg-[#111827]`,
      false: t``,
    },
  },
});

type Props = {
  size?: "sm" | "md" | "lg";
  white?: boolean;
  background?: boolean;
  css?: CSS;
};

export const Logo = makeComponent({
  from: logo,
  as: "svg",
  name: "Logo",
  useOverride() {
    return {
      width: 609,
      height: 609,
      viewBox: "0 0 609 609",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      children: (
        <g>
          <circle cx="144.066" cy="144.066" r="144.066" />
          <circle cx="464.218" cy="144.066" r="144.066" />
          <circle cx="144.066" cy="464.213" r="144.066" />
        </g>
      ),
    };
  },
}) as React.ComponentType<Props>;
