import { Button, Input, Text, X, Y } from "@reframe.so/blocks";
import { useRef, useState } from "react";

export const ConfirmModal = ({
  action,
  warning = "This action cannot be undone and may permanently remove your data from our servers.",
  dangerous,
  onClose,
  onConfirm,
}: {
  action: string;
  warning?: string;
  dangerous?: number;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const randomString = useRef("");
  randomString.current = Math.random()
    .toString(16)
    .substring(0, dangerous ?? 0);

  const [typed, setTyped] = useState("");

  const isDisebled = () => {
    if (dangerous) {
      if (typed.length > 0 && typed.toLowerCase() === "yes") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <Y css="hug gap-6 border border-solid border-neutral-50 rounded items-center text-center sm:text-left sm:items-stretch">
      <Y css="gap-3">
        <Text css="text-lg font-semibold">Are you absolutely sure?</Text>

        <Text css="text-sm text-neutral-400 max-w-md">{warning}</Text>
      </Y>

      {dangerous !== undefined && (
        <Y css="gap-2">
          <Text css="text-sm">
            This action is dangerous. Type <strong>YES</strong> to confirm.
          </Text>
          <Input
            placeholder={`Type "YES" to confirm`}
            value={typed}
            onChange={(e) => setTyped(e.target.value)}
          />
        </Y>
      )}
      <X css="justify-center sm:justify-end gap-4">
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          variant="destructive"
          onClick={() => onConfirm()}
          disabled={isDisebled()}
        >
          {action}
        </Button>
      </X>
    </Y>
  );
};
